import React from 'react'
import PaidServices from '../payments/paid-services'

const Subscription = () => {
  return (
    <div> 
      <PaidServices />
     
    </div>
  )
}

export default Subscription